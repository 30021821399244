
    var riot = require('riot')
    
riot.tag2('sitesearch', '<div onclick="{tags.popover.clicked}" class="sitesearch btn-toolbar"><span class="fa-stack fa-lg pull-right"><i class="fa fa-square fa-stack-2x"></i><i class="fa fa-search fa-stack-1x fa-inverse"></i></span></div> <popover placement="left"> <form id="searchform" method="get" action="/search/" onsubmit="{parent.submitsearch}"> <div class="input-group form-group-sm"> <input placeholder="Search Website" name="q" value="" onkeyup="{parent.submitsearch}" class="form-control"> <div class="input-group-btn"> <button id="searchsubmit" type="submit" onclick="{parent.submitsearch}" class="btn btn-default btn-sm searchsubmit"><span class="fa fa-search searchsubmit"></span></button> </div> </div> </form> </popover>', 'popover .popover.left { background-color: #7c1316; } popover .popover.left .arrow:after { border-left-color: #7c1316; }', '', function(opts) {
this.submitsearch = (function(_this) {
  return function(e) {
    if (e.srcElement.classList.contains('searchsubmit') || e.keyCode === "13") {
      return _this.tags.popover.searchform.submit();
    }
  };
})(this);
});
    
  

    var riot = require('riot')
    
riot.tag2('gtranslate', '<a class="fa-stack fa-lg pull-right translatebutton"><i class="fa fa-square fa-stack-2x"></i><i class="fa fa-translate fa-stack-1x fa-inverse"></i></a> <div id="google_translate_element" riot-style="{show ? \'display:block\' : \'display:none\'}"></div>', '', '', function(opts) {
this.show = false;

this.on('mount', function() {
  var tb, te;
  tb = document.querySelector('.translatebutton');
  te = document.querySelector('#google_translate_element');
  return tb.addEventListener('click', (function(_this) {
    return function(e) {
      var ref;
      console.log(_this.show);
      _this.show = (ref = _this.show !== true) != null ? ref : {
        "true": false
      };
      return _this.update();
    };
  })(this));
});
});
    
  
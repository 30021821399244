window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require 'riot-kit/lib/auth/forgot-password'
require 'riot-kit/lib/popover'
require 'riot-kit/lib/gallery'

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './libs/slideshow.coffee'

require './tags/twitter.tag'
require './tags/sitesearch.tag'
require './tags/gtranslate.tag'
require './tags/eventcalendar.tag'

$ = jQuery = window.jQuery = window.$ = require "jquery"
require 'owl.carousel'

window.carousel = =>
  $(document).ready ()=>
    $('body:not(.editmode) .dl_cta.owl-carousel,.twitter-carousel.owl-carousel,eventlist ul.owl-carousel').owlCarousel(
      items:1
      pagination:true
      nav:false
      margin: 0
      autoplay:true
      autoplayHoverPause:true
      loop:true
      lazyLoad:true
      responsive:{
        0 : {items:1}
        480:{items:1}
        768:{items:1}
        992 : {items:1}
      }
    ).addClass('show')


riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

navbar = document.querySelector('.navbar-default')

# intersection observer
ithreshold = 1
window.observerCallback = (entries, observer) ->
  i = 0
  while i < entries.length
    entry = entries[i]
    console.log "io",entry.intersectionRatio
    if entry.intersectionRatio < ithreshold
      navbar.className = navbar.className.replace(RegExp(/ ?navbar-short ?/), '') + ' navbar-short'
      # observer.unobserve entry.target
    else
      navbar.className = navbar.className.replace(RegExp(/ ?navbar-short ?/), '') + ''
      # observer.unobserve entry.target
    i++
  return

options = threshold: ithreshold
observer = new IntersectionObserver(observerCallback, options)
targets = document.querySelectorAll('.observer')
t = 0
if !document.querySelector('body').className.match(/editing/)
  while t < targets.length
    observer.observe targets[t]
    t++


# mobile nav stuff
mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
mo = new mmutationObserver (mutations)->
  m = 0
  # mutations.forEach (mutation)->
  while m < mutations.length
    mutation = mutations[m]
    if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
        subnav.addEventListener 'click',(e)->
          if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
            e.preventDefault()
            subnav.querySelector('a,i').addEventListener 'click',(e)->
              window.location.href = subnav.querySelector('a').href
            subsecs = subnav.querySelectorAll('.dropdown-menu a')
            if subsecs.length > 0
              [].slice.call(subsecs).map (sec)=>
                sec.addEventListener 'click',(e)->
                  window.location.href = sec.href
    m++

mo.observe(document.querySelector('body'),{attributes:true})

window.carousel();